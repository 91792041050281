<template>
  <div>
    <a-row :gutter="16">
      <a-col :sm="24" :lg="7">
        <card>
          <Menu
            :menus="menus"
            :selectedMenu="selectedMenu"
            @menu-change="menuChange"
          />
        </card>
      </a-col>
      <a-col :sm="24" :lg="17">
        <card>
          <div v-if="selectedMenu[0] === 1">
            <personal-information
              :userData="userData"
              :firstClick="firstClickVerify"
              :role="role"
              @click-verify="verifyEmail"
            />
          </div>
          <div v-else-if="selectedMenu[0] === 2">
            <edit-email
              :email="currentEmail"
              :loadingEmailChange="loadingEmailChange"
              @on-change-email="emailChange"
            />
          </div>
          <div v-else-if="selectedMenu[0] === 3">
            <edit-password
              :password="password"
              @on-change-password="passwordChange"
            />
          </div>
        </card>
      </a-col>
    </a-row>
    <!-- <Account /> -->
  </div>
</template>

<script>
// import Account from '@/components/app/Account'
import Card from '@/components/app/Card'
import Menu from '@/components/app/Menu'
import PersonalInformation from '@/components/app/Account/PersonalInformation'
import EditEmail from '@/components/app/Account/EditEmail'
import EditPassword from '@/components/app/Account/EditPassword'
const menus = [
  {
    key: 1,
    icon: 'user',
    name: 'Personal Information',
  },
  {
    key: 2,
    icon: 'mail',
    name: 'Edit Email',
  },
  {
    key: 3,
    icon: 'key',
    name: 'Edit Password',
  },
]
const selectedMenu = [1]
const password = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}
export default {
  components: {
    // Account,
    Card,
    Menu,
    PersonalInformation,
    EditEmail,
    EditPassword,
  },
  data() {
    return {
      menus,
      selectedMenu,
      password,
      userData: {},
      currentEmail: '',
      loadingEmailChange: false,
      firstClickVerify: true,
    }
  },
  methods: {
    menuChange(payload) {
      this.selectedMenu = [payload.key]
    },
    fetchUserById() {
      const payload = {
        id: this.user.id,
        role: this.role,
      }
      this.$store.dispatch('user/GET_USER_BY_ID', { payload })
        .then(res => {
          // console.log(res)
          this.userData = res
          this.currentEmail = res.email
        })
    },
    verifyEmail() {
      if (this.firstClickVerify) {
        this.firstClickVerify = false
        const payload = {
          id: this.user.id,
          email: this.currentEmail,
          role: this.role,
        }
        this.$store.dispatch('user/SEND_EMAIL', { payload })
          .then(res => {
            if (res === 'EMAIL_SENT') {
              this.$notification.warning({
                message: 'Warning',
                description: 'Link verification has been sent. Please check your email.',
              })
            } else {
              this.$notification.error({
                message: 'Error',
                description: 'Link verification has not been sent.',
              })
            }
            setTimeout(() => {
              this.$notification.success({
                message: 'Warning',
                description: 'Your link verification is enabled for now.',
              })
              this.firstClickVerify = true
            }, 15000)
          })
      } else {
        this.$notification.warning({
          message: 'Warning',
          description: 'Your link verification is disabled for 15 second, Try again later.',
        })
      }
    },
    emailChange(payload) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!re.test(String(payload).toLowerCase())) {
        this.$notification.error({
          message: 'Error.',
          description: 'Your email is not valid.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to change your email ? this changes will be need verify your email again</div>
          ),
          onOk: () => {
            const dataIncome = {
              id: this.user.id,
              email: payload,
              role: this.role,
            }
            this.loadingEmailChange = true
            this.$store.dispatch('user/UPDATE_EMAIL', { dataIncome })
              .then(res => {
                this.loadingEmailChange = false
                if (res.message === 'EMAIL_SENT') {
                  this.$notification.warning({
                    message: 'Warning',
                    description: 'Your email has been updated. Please check your email to verify your account',
                  })
                  this.fetchUserById()
                  // this.selectedMenu = [1]
                } else if (res.message === 'IDENTICAL_EMAIL') {
                  this.$notification.warning({
                    message: 'Warning',
                    description: 'There is no change detected.',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error',
                    description: 'Your email has not been updated.',
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    passwordChange(payload) {
      // console.log(payload)
      const { currentPassword, newPassword, confirmNewPassword } = payload
      const newData = {}
      // console.log(this.newPassword.length)
      if (currentPassword && newPassword && confirmNewPassword) {
        if (newPassword.length >= 6) {
          if (newPassword === confirmNewPassword) {
            if (this.role === 'Teacher') {
              newData.tipe = this.role
              newData.username = this.user.nik
            } else if (this.role === 'Student') {
              newData.tipe = this.role
              newData.username = this.user.nis
            } else if (this.role === 'Admin') {
              newData.tipe = this.role
              newData.username = this.user.username
            }
            newData.currentPassword = currentPassword
            newData.newPassword = newPassword
            this.$store.dispatch('user/CHANGE_PASSWORD', {
              newData,
            })
              .then(res => {
                if (res === 'SUCCESS_RESET_PASSWORD') {
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Succes Change Password',
                  })
                  this.password.currentPassword = ''
                  this.password.newPassword = ''
                  this.password.confirmNewPassword = ''
                } else if (res === 'INVALID_PASSWORD') {
                  this.$notification.error({
                    message: 'Sorry.',
                    description: 'Current password do not correct',
                  })
                  this.password.currentPassword = ''
                  this.password.newPassword = ''
                  this.password.confirmNewPassword = ''
                }
              })
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'New password do not match',
            })
          }
        } else {
          this.$notification.error({
            message: 'Error',
            description:
              'New password too short, minimum 6 characters',
          })
        }
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You must fill all forms',
        })
      }
    },
  },
  mounted() {
    this.fetchUserById()
    this.currentEmail = this.user.email
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
}
</script>

<style>
.card-profile {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 40px 20px;
}

.detail .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.detail .btnChange {
  margin-top: 10px;
  height: 48px;
}
</style>
