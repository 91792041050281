<template>
  <div class="personal-info">
    <div class="cui__utils__heading mb-0">
      <strong>PERSONAL INFORMATION</strong>
    </div>
    <hr />
    <!-- {{ userData }} -->
    <a-alert
      v-if="userData.email && userData.status_email === 'unverified'"
      message="Warning"
      type="warning"
      show-icon
      class="my-4"
    >
      <p v-if="firstClick" slot="description">
        Your email has not been verified,
        <a @click.prevent="verifyEmail" class="text-primary"
          >please use this link to verify your account.</a
        >
      </p>
      <p v-else slot="description">
        Your link verification has been disabled for 15s from now, Try again
        later.
      </p>
    </a-alert>
    <div class="container-profile-account mb-4" v-if="role === 'Student'">
      <div
        class="
          kit__utils__avatar
          kit__utils__avatar--size130
          kit__utils__avatar--rounded
          border border-primary
          shadow
        "
      >
        <div class="image-profile-and-button">
          <div class="image-profile">
            <template v-if="!filename">
              <img
                v-if="userData.jenis_kelamin === 'Laki-Laki'"
                src="@/assets/Male-Peci.svg"
                alt="User 11"
              />
              <img
                v-else
                src="@/assets/Female-Hijab.svg"
                alt="User 11"
              />
            </template>
            <template v-else>
              <img
                :src="`${config.apiUrl}/images/${filename}`"
                alt="User 11"
              />
            </template>
          </div>
          <div class="button-upload-image">
            <a-button
              @click.prevent="toggleShowUploadProfile"
              type="default"
              icon="upload"
              shape="circle"
            ></a-button>
          </div>
          <upload-profile
            field="upload"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="showUploadProfile"
            :url="`${config.apiUrl}/api/file/upload_profile_murid`"
            :width="300"
            :height="300"
            :params="params"
            :headers="headers"
            langType="en"
            :noSquare="true"
            img-format="png"
            :withCredentials="false"
          ></upload-profile>
        </div>
      </div>
    </div>
      <!-- <img :src="imgDataUrl"> -->
    <a-row :gutter="[32, 16]">
      <a-col :sm="24" :lg="12">
        <a-form-item v-if="role === 'Teacher'" label="NIK">
          <a-input size="large" :value="userData.nik" :disabled="true" />
        </a-form-item>
        <a-form-item v-else-if="role === 'Student'" label="NIS">
          <a-input size="large" :value="userData.nis" :disabled="true" />
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="12">
        <a-form-item label="Name">
          <a-input size="large" :value="userData.nama" :disabled="true" />
        </a-form-item>
      </a-col>
      <a-col :sm="24" :md="24" :lg="12">
        <a-form-item label="Gender">
          <a-radio-group
            class="radio-gender w-100"
            :value="userData.jenis_kelamin"
            size="large"
            button-style="solid"
          >
            <a-radio-button
              value="Laki-Laki"
              class="w-50 text-center"
              :class="
                userData.jenis_kelamin === 'Laki-Laki'
                  ? 'bg-primary border border-primary'
                  : ''
              "
              >Laki-Laki</a-radio-button
            >
            <a-radio-button
              value="Perempuan"
              class="w-50 text-center"
              :class="
                userData.jenis_kelamin === 'Perempuan'
                  ? 'bg-primary border border-primary'
                  : ''
              "
              >Perempuan</a-radio-button
            >
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="12">
        <a-form-item label="Email">
          <a-input-search size="large" :value="userData.email" :disabled="true">
            <a-button
              v-if="userData.status_email === 'verified'"
              type="primary"
              icon="check-circle"
              slot="enterButton"
            >
              Verified
            </a-button>
            <a-button v-else icon="minus-circle" slot="enterButton">
              Unverified
            </a-button>
          </a-input-search>
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="24">
        <a-form-item label="Address">
          <a-textarea
            :value="userData.alamat"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :disabled="true"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UploadProfile from 'vue-image-crop-upload'
import config from '@/config'
export default {
  components: { UploadProfile },
  name: 'PersonalInformation',
  props: {
    userData: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    firstClick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showUploadProfile: false,
      params: {
        id_murid: null,
      },
      headers: {
        smail: '*_~',
        token: localStorage.token,
      },
      config,
      filename: null,
    }
  },
  methods: {
    verifyEmail() {
      this.$emit('click-verify')
    },
    toggleShowUploadProfile() {
      this.params.id_murid = this.userData.id
      this.showUploadProfile = !this.showUploadProfile
    },
    async cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------')
      // console.log(jsonData)
      this.fetchProfPic()
      this.toggleShowUploadProfile()
      // console.log(jsonData.data.file)
      // const { id, filepath } = jsonData.data.file
      // this.menuName.murid.id_profil = id
      // this.menuName.murid.file.filepath = filepath
      // this.fetchPersonalData(this.header.idKelas)
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
    },
    cropSuccess(imgDataUrl, field) {
      this.params.id_murid = this.userData.id
      // console.log(imgDataUrl)
      // this.imgDataUrl = imgDataUrl
    },
    async fetchProfPic() {
      try {
        const { file } = await this.$store.dispatch('user/FETCH_PROFILE_PICTURE')
        if (file) this.filename = file.filename
      } catch (err) {
        console.log(err)
      }
    },
  },
  async created() {
    if (this.role === 'Student') await this.fetchProfPic()
  },
}
</script>

<style lang="scss">
.personal-info {
  .container-profile-account {
    .kit__utils__avatar {
      margin: 0 auto;
      overflow: visible;
      .image-profile-and-button {
        position: relative;
        .image-profile img {
          border-radius: 50%;
        }
        .button-upload-image {
          position: absolute;
          bottom: -15px;
          left: 40%;
        }
      }
    }
  }
}
</style>
